@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url("./assets/fonts/quicksand.ttf") format("truetype");
  font-display: swap;
}

@layer base {
  html {
    font-family: Quicksand, system-ui, sans-serif;
  }
}

body {
  /* background-image: url("./assets/img/night-sky.jpg");
  background-size: cover; */
  @apply bg-gradient-to-br from-cyan-700 to-blue-700 min-h-screen;
  /* @apply bg-gradient-to-br from-gray-900 to-zinc-900 min-h-screen; */
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
